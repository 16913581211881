@mixin colored-shadows($value){
  // new box shadow optimized for Tablets and Phones
  box-shadow: 0 2px 2px 0 rgba($value, .1),
              0 3px 1px -2px rgba($value, .18),
              0 1px 5px 0 rgba($value, .15);
}

@mixin colored-shadows-hover($value){
    box-shadow: 0 8px 14px -8px rgba($value, .3),
                0 3px 18px 0 rgba($value, .1),
                0 7px 8px -4px rgba($value, .18);
}
