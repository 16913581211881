// Avatar
$avatar-height:               48px !default;
$avatar-width:                48px !default;

$avatar-xs-height:            24px !default;
$avatar-xs-width:             24px !default;

$avatar-sm-height:            36px !default;
$avatar-sm-width:             36px !default;

$avatar-lg-height:            58px !default;
$avatar-lg-width:             58px !default;

$avatar-xl-height:            74px !default;
$avatar-xl-width:             74px !default;

$avatar-xxl-height:           110px !default;
$avatar-xxl-width:            110px !default;

$avatar-font-size:            1rem !default;
$avatar-content-margin:       .75rem !default;


// Avatar Group
$avatar-group-border:         2px !default;
$avatar-group-zindex:         2 !default;
$avatar-group-zindex-hover:   3 !default;
$avatar-group-double:         -0.75rem !default;
