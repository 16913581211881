:root{
  --map-tiles-filter: invert(100%) hue-rotate(180deg) brightness(80%)
    contrast(90%) saturate(0.5);
  --topo-tiles-filter: brightness(60%) contrast(100%);
    $fixed-plugin-button-z-index: 1001;
    .table {
      --bs-table-color: #454545;
    }
}
:root.dark-version{

    .card{
      --bs-card-color: #d9d9d9;
    }
    .dropdown-menu{
      --bs-dropdown-link-color: #b3b3b3;
    }
    .btn-outline-primary{
      --bs-btn-color: #9c8186;
      --bs-btn-border-color: #9c8186;
    }
    

    --ck-color-base-background: $dark-version-card-bg-color;
    
    /* Overrides the border radius setting in the theme. */
    --ck-border-radius: 4px;

    /* Overrides the default font size in the theme. */
    --ck-font-size-base: 14px;

    /* Helper variables to avoid duplication in the colors. */
    --ck-custom-background: hsl(270, 1%, 29%);
    --ck-custom-foreground: hsl(255, 3%, 18%);
    --ck-custom-border: hsl(300, 1%, 22%);
    --ck-custom-white: hsl(0, 0%, 100%);

    /* -- Overrides generic colors. ------------------------------------------------------------- */

    --ck-color-base-foreground: var(--ck-custom-background);
    --ck-color-focus-border: hsl(208, 90%, 62%);
    --ck-color-text: hsl(0, 0%, 98%);
    --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
    --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

    /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

    --ck-color-button-default-background: var(--ck-custom-background);
    --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
    --ck-color-button-default-active-background: hsl(270, 2%, 20%);
    --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
    --ck-color-button-default-disabled-background: var(--ck-custom-background);

    --ck-color-button-on-background: var(--ck-custom-foreground);
    --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
    --ck-color-button-on-active-background: hsl(255, 4%, 14%);
    --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
    --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

    --ck-color-button-action-background: hsl(168, 76%, 42%);
    --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
    --ck-color-button-action-active-background: hsl(168, 76%, 36%);
    --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
    --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
    --ck-color-button-action-text: var(--ck-custom-white);

    --ck-color-button-save: hsl(120, 100%, 46%);
    --ck-color-button-cancel: hsl(15, 100%, 56%);

    /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

    --ck-color-dropdown-panel-background: var(--ck-custom-background);
    --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-dialog class colors. ----------------------------------- */

    --ck-color-dialog-background: var(--ck-custom-background);
    --ck-color-dialog-form-header-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

    --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
    --ck-color-split-button-hover-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

    --ck-color-input-background: var(--ck-custom-background);
    --ck-color-input-border: hsl(257, 3%, 43%);
    --ck-color-input-text: hsl(0, 0%, 98%);
    --ck-color-input-disabled-background: hsl(255, 4%, 21%);
    --ck-color-input-disabled-border: hsl(250, 3%, 38%);
    --ck-color-input-disabled-text: hsl(0, 0%, 78%);

    /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

    --ck-color-labeled-field-label-background: var(--ck-custom-background);

    /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

    --ck-color-list-background: var(--ck-custom-background);
    --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
    --ck-color-list-button-on-background: var(--ck-color-base-active);
    --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
    --ck-color-list-button-on-text: var(--ck-color-base-background);

    /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

    --ck-color-panel-background: var(--ck-custom-background);
    --ck-color-panel-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

    --ck-color-toolbar-background: var(--ck-custom-background);
    --ck-color-toolbar-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

    --ck-color-tooltip-background: hsl(252, 7%, 14%);
    --ck-color-tooltip-text: hsl(0, 0%, 93%);

    /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);

    /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

    --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
    --ck-color-widget-hover-border: hsl(43, 100%, 68%);
    --ck-color-widget-editable-focus-background: var(--ck-custom-white);

    /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

    --ck-color-link-default: hsl(190, 100%, 75%);
}

button.highcharts-a11y-proxy-button{
  display: none;
}
.table-forced-wrap th,
.table-forced-wrap td{
  white-space: normal !important;
}
.btn.dropdown-toggle,
input.form-control:not([type=tel]){
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
/* Apply to all elements */
* {
  scrollbar-width: thin;  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.05); /* Thumb and Track color for Firefox */
}

/* Webkit-based browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px;  /* Vertical scrollbar width */
  height: 8px; /* Horizontal scrollbar height */
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);  /* Track (background) color */
  border-radius: 5px;  /* Rounded corners for track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);  /* Thumb (scroll handle) color */
  border-radius: 5px;  /* Rounded corners for the thumb */
  border: 2px solid rgba(0, 0, 0, 0.1);  /* Optional: light border around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);  /* Slightly darker thumb color when hovered */
}

.disable-scroll-on-hover:hover {
  pointer-events: none;
}
.text-white-dark{
  color: #262626 !important;
}
.bg-white-dark{
  background-color: #fff !important;
}

label.error{
  color: $danger;
}
.offcanvas {
  background-color: $card-bg-second;
}
.modal-content {
  background-color: $card-bg-second;
}

.offcanvas-large{
  width: 600px !important;
}
.d-light-none{
  display: none;
}

.form-label{
  display: flex;
  align-items: center;
  margin: 0;
}

.btn-close{
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}
.bootstrap-select .dropdown-menu:not(.show){
  display: none;
}
.bootstrap-select .dropdown-menu ul.dropdown-menu.show {
  margin-top: 0 !important;
}
.bootstrap-select button.dropdown-toggle, .bootstrap-select button.dropdown-toggle:focus, .bootstrap-select button.dropdown-toggle.show{
  border-radius: 0 !important;
  background-image: linear-gradient(0deg, $primary 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, hsla(0, 0%, 82%, 0) 0);
}
.bootstrap-select button.dropdown-toggle:focus, .bootstrap-select button.dropdown-toggle.show{
  background-size: 100% 100%, 100% 100%;
  outline: 0 !important;
}
.bootstrap-select button.dropdown-toggle{
  // background: no-repeat bottom, 50% calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: 0.2s ease;
  border: none;
  outline: 0;
}

.table-flex-2 tbody {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.table-flex-2 tr {
  display: flex;
  flex: 0 0 calc(50% - 10px);  /* 2 columns, with spacing */
}

.table-flex-2 td {
  flex: 1;
}

.bg-light-theme{
  background-color: $gray-100 !important;
}

.dropdown ul.dropdown-menu {
  z-index: 1002;
}
.dropdown .dropdown-menu {
  z-index: 1002;
}
.bg-card-second{
  background-color: $card-bg-second !important;
}
.card.card-second{
  background-color: $card-bg-second !important;
  .card-header{
    background: transparent;
  }
}
.nav {
  &.nav-pills {
    a.nav-link {
      &.active {
        background-color: #FFFFFF;
      }
    }
  }
}
.brightness-98{
  filter: brightness(0.98);
}
.grayscale-100{
  filter: grayscale(100%);
}
.font-geomanist{
  font-family: 'GeomanistLight', 'Inter' !important;
}
.nav-items-centered .nav-link{
  display: flex;
  justify-content: center;
  align-items: center;
}
.dark-version {
  .iti__country-list {
    background-color: $dark-version-card-2nd-dp;
    color: $dark-version-body-color;
    border-color: rgba(255, 255, 255, 0.04);
  }
  .form-check:not(.form-switch) .form-check-input[type=radio]:after{
    background: $gray-500;
  }
  .text-accent{
    color: $accent-dark !important;
  }
  .dark-brightness-8{
    filter: brightness(0.8);
  }
  .dark-brightness-9{
    filter: brightness(0.9);
  }
  
  .border { 
    border-color: rgba(255, 255, 255, 0.04) !important;
  }
  .plotly-container-main{
    svg.main-svg[style^="background"]{
      background: transparent !important;
    }
    svg.main-svg text{
      fill: $dark-version-body-color !important;
    }
  }
  .rc-anchor-container{
    background-color: $card-bg-second;
    color: $light;
  }
  .bootstrap-tagsinput input{
    color: $light;
  }
  .bg-card-second{
    background-color: $dark-version-card-2nd-dp !important;
  }

  .card.card-second{
    background-color: $dark-version-card-2nd-dp !important;
    .card-header{
      background: transparent;
    }
  }

  .dataTables_wrapper select{
    background-color: #242424;
  }

  .bg-light-theme{
    background-color: $gray-800 !important;
  }
  #privacy p, #terms_of_use p{
    background-color: transparent;
  }
  #privacy span, #terms_of_use span{
    color: $white;
  }
  .dark-bg-gray-800{
    background-color: $gray-800 !important;
    background-image: none !important;
  }
  .form-check:not(.form-switch) .form-check-input[type=radio]:checked {
    border-color: rgb(203.5, 203.5, 203.5);
  }
  .text-white-dark{
    color: #FFFFFF !important;
  }
  .bg-white-dark{
    background-color: $dark-version-card-bg-color !important;
  }
  .bootstrap-tagsinput{
    background-color: #1d1d1d !important;
  }
  .bg-card-theme{
    background-color: $dark-version-card-bg-color;
  }
  .form-control:disabled {
    background-color: #242420;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #737373 !important;
  }
  .dropdown-menu{
    background: $dark-version-card-2nd-dp !important;
  }
  
  .modal-header{
    border-color: rgba(255, 255, 255, 0.04);
  }
  .modal-footer{
    border-color: rgba(255, 255, 255, 0.04);
  }

  .btn-close{
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }
  .nav {
    &.nav-pills {
      background-color: $dark-version-bg-color;
      a.nav-link {
        background-color: transparent;
        color: $white;
        &.active {
          background-color: $dark-version-card-2nd-dp;
        }
        &:hover:not(.active) {
          color: #c0c0c0;
        }
      }
    }
    &.nav-tabs {
      a.nav-link {
        color: $white;
        &.active {
          background-color: #242424;
        }
      }
    }
  }
  .moving-tab .nav-link.active {
    box-shadow: 0px 1px 5px 1px $dark-version-card-bg-color;
    color: $dark !important;
  }
  .offcanvas {
    background-color: $dark-version-card-2nd-dp;
  }
  .modal-content {
    background-color: $dark-version-card-2nd-dp;
  }
  .d-dark-none{
    display: none;
  }
  .d-light-none{
    display: inline-flex;
  }
  .leaflet-tiles-osm {
    filter: var(--map-tiles-filter, none);
  }

  .leaflet-tiles-topo {
    filter: var(--topo-tiles-filter, none);
  }

  // .leaflet-bar {
  //   a,
  //   button {
  //     background: $dark;
  //     color: $white !important;
  //   }
  // }
  // .leaflet-control-layers.leaflet-control {
  //   background: $dark;
  //   color: $white !important;
  // }

  table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
    background-color: rgba(199, 199, 199, 0.2);
  }
  table.dataTable tbody tr.selected, table.dataTable tbody th.selected, table.dataTable tbody td.selected {
    color: #fff;
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

.border-dashed-light {
  border: 1px dashed #d4d4d4 !important
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.truncate-height-2{
  min-height: 3.2em;
}
.text-truncate-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.truncate-height-3{
  min-height: 4.8em;
}

.dataTables_paginate .dataTables_info{
  font-size: 0.875rem !important;
}
.dataTables_filter .form-control, .dataTables_filter .is-focused .form-control {
  background-image: linear-gradient(to top, #9b9094 2px, rgba(155, 144, 148, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.dark-version .dataTables_filter .form-control, .dataTables_filter .is-focused .form-control {
  background-image: $dark-version-input-bg-image !important;
  background-size: 0 100%, 100% 100%;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child, table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child {
  padding-left: 5px !important;
}

.table.dataTable > :not(caption) > * > * {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.btn.btn-sm i, .btn-group-sm > .btn i {
  font-size: 0.85rem;
}

a.accordion-button[aria-expanded="true"] i, 
button.accordion-button[aria-expanded="true"] i{
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: rotate(180deg);
}
.accordion-button::after{
  display: none;
}

.rotate-icon-90 {
  transform: rotate(-90deg);
}

.min-vh-70-responsive {
  min-height: 70vh;
}
@include media-breakpoint-down(lg) {
  .min-vh-70-responsive {
    min-height: 60vh;
  }
}

// Fixed TOC tabs
.bottom-tabs-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 380px;
  z-index: 1005;
}

.tabs-scroll-up {
  display: flex;
  justify-content: center;
  position: absolute;
  right: calc(100% + 5px);
  bottom: 0;
  width: 55px;
  height: 45px;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transform: translateY(100%);
  transition: transform 0.3s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
}
.tabs-scroll-up.is-scroll-up.is-active {
  transform: translateY(0) !important;
}
.tabs-mover {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 420px;
  max-height: calc(100vh - 90px);
  transform: translateY(100%);
  transition: transform 0.5s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  box-shadow: 0 -40px 40px #0009;
}
.tabs-mover .tabs-buttons {
  display: flex;
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 45px;
  width: 100%;
  border-top-left-radius: 0.375rem;
}
.tabs-button {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 45px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #616964;
}
.tabs-button .icon.is-scroll-up {
  display: block;
  position: absolute;
  top: -9px;
  left: -8px;
  width: 16px;
  height: 21px;
  opacity: .5;
}
.tabs-targets {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
}
.dark-version .tabs-targets {
  background: #242424;
}
.tabs-mover .tabs-target.is-active {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.2s;
}
.tabs-mover .tabs-target {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}
.tabs-mover .scroll-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tabs-mover .scroll-mover {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 40px);
  height: 100%;
  padding: 25px 55px 25px 25px;
  overflow-y: scroll;
}
.tabs-mover.is-visible {
  transform: translateY(0);
}
.content-on-page li {
  padding-left: 1.75em;
  line-height: 1.5em;
  list-style: none;
}
.content-on-page .link {
  position: relative;
  display: block;
  color: #616964;
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.content-on-page .link .label {
  transition: opacity 0.15s;
  color: #999999;
}
.content-on-page .link.active .label {
  //  font-weight: 600;
  color: darken($color: #999999, $amount: 20);
}
.content-on-page .link .dot {
  position: absolute;
  top: 50%;
  left: -20px;
  width: 0;
  height: 0;
}
.content-on-page .link .path {
  position: absolute;
  bottom: 0;
  left: -20px;
  width: 0;
  height: 0;
}
.content-on-page li:last-child > .link:not(.is-parent) .path {
  display: none;
}
.content-on-page .link.is-default .path .line {
  position: absolute;
  top: -4px;
  left: -1px;
  display: block;
  width: 2px;
  height: 8px;
  background: #999999;
}
.content-on-page .link .dot .disc {
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #999999;
  transition: transform 0.15s;
}
.content-on-page .link.active .dot .disc {
  transform: scale(1.5);
  background: #c5517a;
}
.tabs-scroll-up:hover .icon.is-scroll-up svg .arrow {
  animation: scroll-up-icon 2s infinite;
}

@keyframes scroll-up-icon {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(6px);
  }
  20% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(6px);
  }
  40% {
    transform: translateY(0);
  }
}


.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;

  direction: ltr;
}
.fileinput {
  display: inline-block;
  margin-bottom: 9px;
}
.fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
}
.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 250px;


  &.img-circle{
      border-radius: 50%;
      max-width: 100px;
  }
}
.fileinput .thumbnail > img {
  max-height: 100%;
  width: 100%;
}
.fileinput .btn {
  vertical-align: middle;
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
  display: table;
}
.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}
.fileinput.input-group > .btn-file {
  z-index: 1;
}
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}
.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}
.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}
.form-group.has-warning .fileinput .fileinput-preview {
  color: $warning;
}
.form-group.has-warning .fileinput .thumbnail {
  border-color: $warning;
}
.form-group.has-error .fileinput .fileinput-preview {
  color: $danger;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: $danger;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: $success;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: $success;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
.thumbnail{
    border: 0 none;
    border-radius: 4px;
    padding: 0;
}


.dark-version{
  .flatpickr-calendar {
    background: transparent;
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    -webkit-animation: none;
            animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 307.875px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    background: $dark-version-card-2nd-dp;
    -webkit-box-shadow: 1px 0 0 #20222c, -1px 0 0 #20222c, 0 1px 0 #20222c, 0 -1px 0 #20222c, 0 3px 13px rgba(0,0,0,0.08);
            box-shadow: 1px 0 0 #20222c, -1px 0 0 #20222c, 0 1px 0 #20222c, 0 -1px 0 #20222c, 0 3px 13px rgba(0,0,0,0.08);
  }
  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
  }
  .flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
  }
  .flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
  }
  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
  }
  .flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
  }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
            box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  }
  .flatpickr-calendar .hasWeeks .dayContainer,
  .flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
  }
  .flatpickr-calendar.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #20222c;
  }
  .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }
  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
  }
  .flatpickr-calendar.rightMost:before,
  .flatpickr-calendar.arrowRight:before,
  .flatpickr-calendar.rightMost:after,
  .flatpickr-calendar.arrowRight:after {
    left: auto;
    right: 22px;
  }
  .flatpickr-calendar.arrowCenter:before,
  .flatpickr-calendar.arrowCenter:after {
    left: 50%;
    right: 50%;
  }
  .flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px;
  }
  .flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px;
  }
  .flatpickr-calendar.arrowTop:before,
  .flatpickr-calendar.arrowTop:after {
    bottom: 100%;
  }
  .flatpickr-calendar.arrowTop:before {
    border-bottom-color: #20222c;
  }
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: $dark-version-card-2nd-dp;
  }
  .flatpickr-calendar.arrowBottom:before,
  .flatpickr-calendar.arrowBottom:after {
    top: 100%;
  }
  .flatpickr-calendar.arrowBottom:before {
    border-top-color: #20222c;
  }
  .flatpickr-calendar.arrowBottom:after {
    border-top-color: $dark-version-card-2nd-dp;
  }
  .flatpickr-calendar:focus {
    outline: 0;
  }
  .flatpickr-wrapper {
    position: relative;
    display: inline-block;
  }
  .flatpickr-months {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .flatpickr-months .flatpickr-month {
    background: $dark-version-card-2nd-dp;
    color: #fff;
    fill: #fff;
    height: 34px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: #fff;
    fill: #fff;
  }
  .flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
  .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
    display: none;
  }
  .flatpickr-months .flatpickr-prev-month i,
  .flatpickr-months .flatpickr-next-month i {
    position: relative;
  }
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
    left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
  }
  /*
        /*rtl:begin:ignore*/
  /*
        /*rtl:end:ignore*/
  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
    right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
  }
  /*
        /*rtl:begin:ignore*/
  /*
        /*rtl:end:ignore*/
  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover {
    color: #eee;
  }
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: #f64747;
  }
  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
  }
  .flatpickr-months .flatpickr-prev-month svg path,
  .flatpickr-months .flatpickr-next-month svg path {
    -webkit-transition: fill 0.1s;
    transition: fill 0.1s;
    fill: inherit;
  }
  .numInputWrapper {
    position: relative;
    height: auto;
  }
  .numInputWrapper input,
  .numInputWrapper span {
    display: inline-block;
  }
  .numInputWrapper input {
    width: 100%;
  }
  .numInputWrapper input::-ms-clear {
    display: none;
  }
  .numInputWrapper input::-webkit-outer-spin-button,
  .numInputWrapper input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(255,255,255,0.15);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .numInputWrapper span:hover {
    background: rgba(192,187,167,0.1);
  }
  .numInputWrapper span:active {
    background: rgba(192,187,167,0.2);
  }
  .numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute;
  }
  .numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
  }
  .numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(255,255,255,0.6);
    top: 26%;
  }
  .numInputWrapper span.arrowDown {
    top: 50%;
  }
  .numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(255,255,255,0.6);
    top: 40%;
  }
  .numInputWrapper span svg {
    width: inherit;
    height: auto;
  }
  .numInputWrapper span svg path {
    fill: rgba(255,255,255,0.5);
  }
  .numInputWrapper:hover {
    background: rgba(192,187,167,0.05);
  }
  .numInputWrapper:hover span {
    opacity: 1;
  }
  .flatpickr-current-month {
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 7.48px 0 0 0;
    line-height: 1;
    height: 34px;
    display: inline-block;
    text-align: center;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  .flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
  }
  .flatpickr-current-month span.cur-month:hover {
    background: rgba(192,187,167,0.05);
  }
  .flatpickr-current-month .numInputWrapper {
    width: 6ch;
    width: 7ch\0;
    display: inline-block;
  }
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: #fff;
  }
  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: #fff;
  }
  .flatpickr-current-month input.cur-year {
    background: transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .flatpickr-current-month input.cur-year:focus {
    outline: 0;
  }
  .flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(255,255,255,0.5);
    background: transparent;
    pointer-events: none;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    background: $dark-version-card-2nd-dp;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: initial;
    -webkit-box-sizing: border-box;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
  .flatpickr-current-month .flatpickr-monthDropdown-months:active {
    outline: none;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: rgba(192,187,167,0.05);
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: $dark-version-card-2nd-dp;
    outline: none;
    padding: 0;
  }
  .flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    height: 28px;
  }
  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: $dark-version-card-2nd-dp;
    color: #fff;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    font-weight: bolder;
  }
  .dayContainer,
  .flatpickr-weeks {
    padding: 1px 0 0 0;
  }
  .flatpickr-days {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 307.875px;
  }
  .flatpickr-days:focus {
    outline: 0;
  }
  .dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 307.875px;
    min-width: 307.875px;
    max-width: 307.875px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  .dayContainer + .dayContainer {
    -webkit-box-shadow: -1px 0 0 #20222c;
            box-shadow: -1px 0 0 #20222c;
  }
  .flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: rgba(255,255,255,0.95);
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    -webkit-flex-basis: 14.2857143%;
        -ms-flex-preferred-size: 14.2857143%;
            flex-basis: 14.2857143%;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: #646c8c;
    border-color: #646c8c;
  }
  .flatpickr-day.today {
    border-color: #eee;
  }
  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    border-color: #eee;
    background: #eee;
    color: $dark-version-card-2nd-dp;
  }
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #80cbc4;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    border-color: #80cbc4;
  }
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 #80cbc4;
            box-shadow: -10px 0 0 #80cbc4;
  }
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
  }
  .flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
            box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
  }
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    color: rgba(255,255,255,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
  }
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: rgba(255,255,255,0.1);
  }
  .flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
            box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
  }
  .flatpickr-day.hidden {
    visibility: hidden;
  }
  .rangeMode .flatpickr-day {
    margin-top: 1px;
  }
  .flatpickr-weekwrapper {
    float: left;
  }
  .flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #20222c;
            box-shadow: 1px 0 0 #20222c;
  }
  .flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  .flatpickr-weekwrapper span.flatpickr-day,
  .flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(255,255,255,0.3);
    background: transparent;
    cursor: default;
    border: none;
  }
  .flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
  }
  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .flatpickr-time:after {
    content: "";
    display: table;
    clear: both;
  }
  .flatpickr-time .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 40%;
    height: 40px;
    float: left;
  }
  .flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(255,255,255,0.95);
  }
  .flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(255,255,255,0.95);
  }
  .flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
  }
  .flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
  }
  .flatpickr-time input {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: rgba(255,255,255,0.95);
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .flatpickr-time input.flatpickr-hour {
    font-weight: bold;
  }
  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    font-weight: 400;
  }
  .flatpickr-time input:focus {
    outline: 0;
    border: 0;
  }
  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    color: rgba(255,255,255,0.95);
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
  }
  .flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }
  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background: #6a7395;
  }
  .flatpickr-input[readonly] {
    cursor: pointer;
  }
  @-webkit-keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
}